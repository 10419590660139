<template>
  <!-- 添加备注 -->
  <ShipDialog
      :title="title"
      :visible.sync="dialogTableVisible"
      :showTab="showTab"
      width="500px"
      size="small"
      center
      append-to-body
      :show-close="false"
  >
    <div class="ticket_div">
      <p>使用多提单号搜索将自动忽略日期、船代、船名航次、用户等搜索条件</p>
      <el-input
          type="textarea"
          @input="formatBills"
          :autosize="{minRows: 4, maxRows: 6}"
          placeholder="请输入提单号并用'逗号'分隔"
          v-model="textarea"
      ></el-input>
    </div>
    <DialogFooter
        :showTab="showTab"
        @confirm="addBills"
        @cancel="closeDialog"
        :loading="confirmLoading"
    ></DialogFooter>
  </ShipDialog>
</template>
<script>
import {updateRemarkAndTruck, remarkShVessels} from "@/api/boxQuery";
import {shipRequest} from "@/views/ShipSubscrib/ship.js";
import {updateTruckSubRemark} from "@/api/truckSubscribe";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import {getUserRemarkTags} from "@/api/shanghaiManifest";

export default {
  props: ["showTab"],
  data() {
    return {
      title: "搜索多提单号",
      textarea: "",
      confirmLoading: false,
      dialogTableVisible: false,
    };
  },
  components: {
    ShipDialog,
    DialogFooter,
  },
  mounted() {
  },
  methods: {
    // 船期/物流备注
    init(billNos) {
      this.dialogTableVisible = true;
      this.textarea = billNos;
    },
    formatBills(val) {
      this.textarea = val.trim().replace(' ', ',').replace(/[^a-zA-Z0-9,，]/g, '').toUpperCase();
    },
    closeDialog() {
      this.dialogTableVisible = false;
    },
    addBills() {
      this.$emit("searchBills", this.textarea);
      this.closeDialog();
    },
  },
};
</script>
<style lang="stylus" scoped>
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.el_select {
  margin-bottom: 1rem;
}

.el-image {
  width: 30rem;
  height: 30rem;
}
</style>
