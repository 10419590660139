<template>
  <div>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="40%"
        append-to-body
        center
    >
      <el-table :data="data" style="width: 100%" stripe border
                max-height="500"
                :header-cell-style="{background: '#d4d9e1',color: '#606278'}"
      >
        <el-table-column
            prop="billno"
            label="提单号"
            width="200"
        ></el-table-column>
        <el-table-column
            prop="vesselname"
            label="船名"
        ></el-table-column>
        <el-table-column prop="voyageno" label="航次" width="160"></el-table-column>
        <el-table-column prop="fcwStatusStr" label="原因" width="160" v-if="[0,1].includes(type)"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="operator('confirm')" type="primary" v-if="[2].includes(type)">
                    {{ type === 2 ? '确认发送' : '确定' }}
                </el-button>
                <el-button @click="operator('cancel')">
                    关 闭
                </el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

import {fa} from "vue-video-player/languages";

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      data: [],
      title: '',
      type: 0,
      titles: ['删除失败', '发送失败', '需要等通知，确认发送吗？'],
    };
  },
  methods: {
    init(list, type = 0) {
      this.type = type;
      this.title = '以下舱单' + this.titles[type];
      this.dialogVisible = true;
      this.data = list;
    },
    operator(type) {
      this.$emit(type);
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="stylus" scoped>

/deep/ .el-table .el-table__body td.el-table__cell {
  font-weight: 500;
  p {
    font-weight: 500;
  }

  span {
    font-weight: 500;
  }
}
</style>
