<template>
    <div :class="{ 'common_header_wrap': true, 'small_size': smallSize && fit }">
        <vue-particles color="#dedede" class="canvas_div"></vue-particles>
        <div class="header-content">
            <h1 v-if="title" class="title">{{ title }}</h1>
            <slot name="header"></slot>
            <slot name="content"></slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        criticalWidth: {
            type: Number, // 宽度临界值，小于词值要调整高度以适应内容
            default: 1610
        },
        fit: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        smallSize() { 
            return this.$store.state.other.screenWidth < this.criticalWidth
        }
    },
};
</script>

<style lang="stylus" scoped>
.common_header_wrap {
    position: relative;
    width: 100%;
    height: 160px;
    &.small_size {
        height: 210px;
    }
    .canvas_div {
        height: 100%;
        width: 100%;
    }

    .title {
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        line-height: 1.5;
        margin: 0;
        user-select: none;
      }

    .header-content {
        width: 100%;
        top: 0;
        position: absolute;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 10px 0;
        box-sizing:border-box;
    }
}
</style>
